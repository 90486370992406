import { FC, useMemo } from 'react'
import {
  Case,
  CaseArcadeType,
  CaseCutType,
  CaseTargetType,
  isSecretRetainer,
} from '@app/services/cases/types'
import { Card, Detail, TeethSelect } from '@app/components'
import { useTranslation } from 'react-i18next'
import { getLabelByEnumValue } from '@app/utils'

export const CaseDetailInformation: FC<{ caseDetail: Case }> = ({
  caseDetail,
}) => {
  const { t } = useTranslation()

  const getCaseProblems = useMemo(() => {
    return caseDetail.specificInfo.treatmentTarget.patientProblems?.map(
      problem => (
        <div key={problem.problemId}>
          <div className="lead fs-6">{t(`problems.${problem.problem}`)}</div>
          <p>
            {problem.solution
              ? t(`solutions.${problem.solution}`)
              : problem.otherSolution}
          </p>
        </div>
      ),
    )
  }, [caseDetail.specificInfo.treatmentTarget.patientProblems, t])

  const getSecretRetainerCompletionText = useMemo(() => {
    const secretRetainers = caseDetail.specificInfo.details.secretRetainers

    if (secretRetainers?.requestOnFinish) {
      return getLabelByEnumValue(
        CaseArcadeType,
        secretRetainers?.archs,
        'cases.arcade',
        t,
      )
    } else {
      return secretRetainers?.requestOnFinish
    }
  }, [caseDetail.specificInfo.details.secretRetainers, t])

  return (
    <Card.Body size={'sm'}>
      <Detail.Section>
        <>
          <Detail.SubTitle>{t('cases.general-information')}</Detail.SubTitle>

          {!isSecretRetainer(caseDetail.type) && (
             <Detail.Cell className="col-md-12" label={t('cases.teeth-not-move')}>
              <TeethSelect
                arcadeType={caseDetail.generalInfo.patient.archTreat}
                selectedTeeth={caseDetail.generalInfo.patient.teethNotToMove}
              />
            </Detail.Cell>
          )}


          <Detail.Cell label={t('cases.reference')} value={caseDetail.id} />

          <Detail.Cell
            label={t('cases.name')}
            value={caseDetail.generalInfo.patient?.name}
          />

          <Detail.Cell
            label={t('cases.gender.title')}
            value={t(`cases.gender.${caseDetail.generalInfo.patient?.gender}`)}
          />

          <Detail.Cell
            label={t('cases.birthDate')}
            value={caseDetail.generalInfo.patient?.birthdate}
          />

          <Detail.Cell
            label={t('cases.arcade.title')}
            value={getLabelByEnumValue(
              CaseArcadeType,
              caseDetail.generalInfo.patient.archTreat,
              'cases.arcade',
              t,
            )}
          />

          <Detail.Cell
            label={t('cases.clinic')}
            value={caseDetail.generalInfo.clinic?.name}
          />

          <Detail.Cell
            label={t('cases.billing-address')}
            value={caseDetail.generalInfo.billingAddress?.name}
          />

          <Detail.Cell
            label={t('cases.comments')}
            value={caseDetail.generalInfo.comments}
          />

          <Detail.SubTitle>{t('cases.specific-information')}</Detail.SubTitle>

          <Detail.Cell
            label={t('cases.cut-type.title')}
            value={getLabelByEnumValue(
              CaseCutType,
              caseDetail.specificInfo?.details.cutType,
              'cases.cut-type',
              t,
            )}
          />

          <Detail.Cell
            label={t('cases.secret-retainer.completion')}
            value={getSecretRetainerCompletionText}
          />

          <Detail.Cell
            label={t('cases.treatment-target.title')}
            value={getLabelByEnumValue(
              CaseTargetType,
              caseDetail.specificInfo.treatmentTarget.target,
              'cases.treatment-target',
              t,
            )}
          />

          <Detail.Cell
            label={t('cases.use-elastics')}
            value={caseDetail.specificInfo.details.useElastics}
          />

          <Detail.Cell
            label={t('cases.distalize')}
            value={caseDetail.specificInfo.details.distalize}
          />

          <Detail.Cell
            label={t('cases.third-molar-extraction')}
            value={caseDetail.specificInfo.details.thirdMolarExtraction}
          />

          <Detail.Cell label={t('cases.prescription.title')}>
            {getCaseProblems}
          </Detail.Cell>

          <Detail.Cell
            label={t('cases.indications')}
            value={caseDetail.specificInfo.indications}
          />
        </>
      </Detail.Section>
    </Card.Body>
  )
}
