import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import {
  InputControl,
  RadioControl,
  SelectControl,
  TextareaControl,
} from '@app/components/forms'
import { Card, TeethSelect } from '@app/components'
import {
  CaseArcadeType,
  CasesTeethNotMove,
  GenderType,
  isSecretRetainer,
} from '@app/services/cases/types'
import { ClinicBase } from '@app/services/clinics/types'
import { BillingAddress } from '@app/services/billing-address/types'
import { CaseFormFields } from '../types'
import { mapEnumIntoIterableTypeValue } from '@app/utils'

const arcadeTypeOptions = mapEnumIntoIterableTypeValue(
  CaseArcadeType,
  'cases.arcade',
)
const genderTypeOptions = mapEnumIntoIterableTypeValue(
  GenderType,
  'cases.gender',
)

type GeneralInfoFormProps = {
  clinics: ClinicBase[]
  billingAddress: BillingAddress[]
}

export const GeneralInfoForm: FC<GeneralInfoFormProps> = ({
  clinics,
  billingAddress,
}) => {
  const { t } = useTranslation()
  const { watch, setValue } = useFormContext<CaseFormFields>()
  const archTreatWatch = watch('general.archTreat') || CaseArcadeType.Upper
  const teethNotToMoveWatch = watch('general.teethNotToMove')

  const [selectedTeeth, setSelectedTeeth] =
    useState<CasesTeethNotMove[]>(teethNotToMoveWatch)

  const caseType = watch('type')

  useEffect(() => {
    setValue('general.teethNotToMove', selectedTeeth)
  }, [setValue, selectedTeeth])

  return (
    <Card.Body size={'sm'} className="GeneralInfo">
      <div className="row">
        <h3 className="form-subtitle mt-0">{t('cases.patient-info')}</h3>
        <div className="col-6">
          <InputControl
            type="text"
            name="general.firstName"
            label={t('cases.name')}
            placeholder={t('cases.name')}
            required
          />
        </div>
        <div className="col-6">
          <InputControl
            type="text"
            name="general.lastName"
            label={t('cases.surname')}
            placeholder={t('cases.surname')}
            required
          />
        </div>
        <div className="col-6">
          <InputControl
            type="text"
            name="general.birthdate"
            slotLabel={t('cases.birthDate-placeholder')}
            label={t('cases.birthDate')}
            placeholder={t('cases.birthDate')}
            required
          />
        </div>
        <div className="col-6">
          <RadioControl
            className="CaseType-control"
            name="general.gender"
            label={t('cases.gender.title')}
            required
            options={genderTypeOptions?.map(d => ({
              label: t(d.type),
              value: d.value,
            }))}
          />
        </div>
        <div className="col-6">
          <SelectControl
            name="general.archTreat"
            label={t('cases.arcade.title')}
            options={arcadeTypeOptions?.map(d => ({
              label: t(d.type),
              value: d.value as unknown as string,
            }))}
            required
          />
        </div>
        {!isSecretRetainer(caseType) && (
          <>
            <h3 className="form-subtitle">{t('cases.teeth-not-move')}</h3>
            <p>{t('cases.teeth-not-move-body')}</p>
            <div className="col-12">
              <TeethSelect
                arcadeType={archTreatWatch}
                selectedTeeth={selectedTeeth}
                setSelectedTeeth={setSelectedTeeth}
              />
            </div>
          </>
        )}

        <h3 className="form-subtitle">{t('cases.invoicing-data')}</h3>
        <div className="col-6">
          <SelectControl
            name="general.clinicId"
            label={t('cases.clinic')}
            options={clinics?.map(d => ({
              label: d.name,
              value: d.id,
            }))}
            required
          />
        </div>
        <div className="col-6">
          <SelectControl
            name="general.billingAddressId"
            label={t('cases.billing-address')}
            options={billingAddress?.map(d => ({
              label: d.name,
              value: d.id,
            }))}
            required
          />
        </div>
        <div className="col-12">
          <TextareaControl
            rows={5}
            name="general.comments"
            label={t('cases.comments')}
          />
        </div>
      </div>
    </Card.Body>
  )
}
